















































































































import dayjs from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProgrammaticVoucher } from '@/domain/entities/ProgrammaticVoucher'
import Badge from '@/app/ui/components/Badge/index.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Badge,
  },
})
export default class ProgrammaticVoucherItem extends Vue {
  @Prop() private isGuest!: boolean
  @Prop() private voucherItem!: ProgrammaticVoucher

  get statusBadgeType(): string {
    if (
      this.voucherItem?.status?.toLowerCase() === 'expired' ||
      this.voucherItem?.status?.toLowerCase() === 'cancelled'
    ) {
      return 'error'
    } else if (
      this.voucherItem?.status?.toLowerCase() === 'active' ||
      this.voucherItem?.status?.toLowerCase() === 'published'
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get isPendingOrActive(): boolean {
    return (
      this.voucherItem.status === 'ACTIVE' ||
      this.voucherItem.status === 'PENDING'
    )
  }

  private getPeriod(voucherItem: ProgrammaticVoucher): string {
    if (voucherItem.voucherTrigger === 'ON_SCHEDULE') {
      return dayjs(voucherItem.scheduledAt).format('DD MMMM YYYY')
    } else {
      return `${dayjs(voucherItem.startDate).format('DD MMMM YYYY')} - ${dayjs(
        voucherItem.endDate
      ).format('DD MMMM YYYY')}`
    }
  }

  private formatDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(date, 'DD MMMM YYYY HH:mm Z')
    )
  }

  get getVoucherItemStatus(): string {
    return Utils.toCapitalize(this.voucherItem.status || '')
  }

  get isHasBudgetAmount(): boolean {
    return !!this.voucherItem.budgetAmount
  }

  get getBudgetUsedAndAmount(): number | string {
    return `Rp${Utils.currencyDigit(
      this.voucherItem.budgetUsed || 0
    )} / Rp${Utils.currencyDigit(this.voucherItem.budgetAmount || 0)}`
  }
}
